html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html,
body {
  overflow-x: hidden;
  line-height: 1.5;
  font-size: 100%;
  background-color: var(--white);
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
} */

/* input {
  all: unset;
} */
@font-face {
  font-family: 'WorkSans';
  src: url('./assets/Work_Sans/WorkSans-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('./assets/SourceSansPro/SourceSansPro-Light.ttf') format('truetype');
}

:root {
  --blue: #19488e;
  --darkBlue: #0b0228;
  --red: #BF0503;
  --white: #ffffff;
  --gray: #00000093;
  /* --blue: #001566; */


  --fontMain: "WorkSans";
  --fontTwo: "SourceSansPro"

  --small-spacing: 16px;
  --medium-spacing: 48px;
  --large-spacing: 80px;
  
  --main-header-padding: 15px;
  --sub-header-padding: 10px;
  --paragraph-padding: 5px;

  --fs-hero: 52px;
  --fs-title: 48px;
  --fs-title2: 30px;
  --fs-subtitle: 20px;
  --fs-ptag: 18px;
  --fs-small: 16px;
  --fs-xs: 14px;

  --btn-fontWeight: 400;
  --subtitle-fontWeight: 500;
  --title-fontWeight: 400;

  --btnRadius: 2px;

}
@media only screen and (max-width: 800px) {

  :root {

  
    --fs-hero: 29px;
    --fs-title: 26px;
    --fs-subtitle: 18px;
    --fs-ptag: 16px;
    --fs-small: 14px;
    --fs-xs: 14px;
  
  }
  html{
    font-size: 60%;
  }

}