#faqSection {
  width: 100vw;
  background-color: var(--blue);
  padding-top: var(--medium-spacing);
  padding-bottom: var(--medium-spacing);
}

#faqFlex {
  display: flex;
  justify-content: center;
}

#faqSection h1 {
  font-size: var(--fs-title);
  color: var(--white);
  font-family: var(--fontMain);
  text-align: center;
  padding-bottom: 50px;
}

.faq-container {
  width: 95%;
  max-width: 1200px;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background-color: var(--white);
}

.faqItem {
  cursor: pointer;
  /* min-height: 0px; */
  max-height: 80px;
  overflow: hidden;
  border-radius: 5px;
  /* padding: 15px; */
  border-bottom: solid 2px var(--blue);
  transition: all 0.2s ease-in-out;
  padding: 15px;
}

.questionTop {
  display: flex;
  align-items: center;
  min-height: 40px;
  color: var(--blue);
  font-family: var(--fontMain);
  font-weight: 400;
  font-size: var(--fs-ptag);
}

#questionIcon {
  width: 25px;
  transition: all 0.1s linear;
  font-size: 32px;
  font-weight: 300;
  color: var(--red);
}

.question {
  margin-left: 15px;
}

.answer {
  color: var(--blue);
  font-family: "SourceSansPro";
  font-size: var(--fs-small);
  opacity: 0;
  transition: all 0.2s ease-in-out;
  padding-left: 10px;
  /* padding-left: 40px;
  padding-right: 15px; */
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
}
.answer p{
  color: var(--blue);
  font-family: var(--fontMain);
  font-weight: 500;
  padding-top: 10px;


  /* padding-left: 40px;
  padding-right: 15px; */
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
}

.selectedQ {
  max-height: 400px;
}

.selectedQ #questionIcon {
  rotate: 45deg;
}

.selectedQ .answer {
  opacity: 100;
  padding: 10px;
}
@media only screen and (max-width: 800px) {

  .faq-container {
    width: 90vw;
    
  }
  }