/* HEADER ======================================================= */
#headerInitial {
    width: 100vw;
    height: 85px;
    background: linear-gradient(45deg,var(--blue) 74.6%, #ffffff 74.6%, #ffffff 75%, var(--red) 75%);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 10;
    display: flex;
    align-items: center;
    scroll-margin:100px ;
}

#headerScrolled {
    background: linear-gradient(45deg,var(--blue) 74.6%, #ffffff 74.6%, #ffffff 75%, var(--red) 75%);
    width: 100vw;
    height: 85px;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 10;
    display: flex;
    align-items: center;
    box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.1);
}

#headerLogo {
    height: 45px;
    margin-left: 40px;
    cursor: pointer;
}
#headerBtnArea {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 35px;
}

.headerBtn {
    margin-right: 30px;
    color: white;
    cursor: pointer;
    font-size: var(--fs-small);
    text-transform: uppercase;
    font-weight: 400;
    font-family: var(--fontMain);

}
#headerScrolled .headerBtn {
    color: var(--white);
}

#headerActNowBtn {
    position: absolute;
    right: 40px;
    height: 35px;
    width: 120px;
    background-color: var(--blue);
    color: white;
    border: 1px solid rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: var(--fontMain);
    text-transform: uppercase;
    font-weight: var(--btn-fontWeight);

    border-radius: var(--btnRadius);
}

#headerScrolled #headerActNowBtn {
    /* background-color: white;
    color: var(--blue); */
    border: 1px solid white;

}

#menuBtn {
    width: 32px;
    top: 20px;
    position: absolute;
    right: 0px;
    cursor: pointer;
    display: none;
    color: white;
}

.dropDownMenu {
    width: 100vw;
    background-color: white;
    position: fixed;
    top: -100%;
    left: 0px;
    z-index: 9;
    transition: all 0.2s ease-out;
    box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.1);
}

#dropDownMenuOpen {
    width: 100vw;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9;
}

#menuOptionArea {
    margin-top: 120px;
}

.menuOption {
    width: 80%;
    margin-left: 10%;
    font-family: var(--fontMain);
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 20px;
    font-size: 15px;
    letter-spacing: 2px;
    cursor: pointer;
    border-bottom: solid 1px gray;
    padding-bottom: 10px;
    margin-bottom: 20px;
    color: var(--blue);
}

#actNowMenuBtn {
    width: 80%;
    margin-left: 10%;
    margin-bottom: 20px;
    height: 40px;
    border-radius: 4px;
    background-color: var(--blue);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: var(--fontMain);
    font-size: 15px;

    text-transform: uppercase;
    cursor: pointer;
    margin-top: 30px;
}

@media only screen and (max-width: 650px) {
    #headerBtnArea {
        display: none;
    }

    #headerLogo {
        margin-left: 20px;
    }

    #headerActNowBtn {
        right: 75px;
        display: none;
    }

    #menuBtn {
        width: 32px;
        top: 26px;
        position: absolute;
        right: 20px;
        cursor: pointer;
        display: initial;
    }
}