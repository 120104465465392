/* HERO SECTION ======================================================= */
#heroSection {
  width: 100vw;
  height: 100vh;
  background-color: gray;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  /* margin-top: 85px; */
  /* position: relative; */
}
#flagConatiner{
  height: 10px;
  position: relative;
  overflow: visible;

}
#flagConatiner img{

  width: 75vw;
  position: absolute;
  top: -190px;
  left: -200px;
  z-index: 5;
  rotate: 11deg;
  /* -webkit-box-reflect: right; */
}

#heroFlex {
  width: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding-top: 11%;
  height: calc(100%);
  /* margin-top: 85px; */
  position: absolute;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.35);
}

#heroArea {
  width: 95%;
  max-width: 1300px;
  min-height: 400px;
  text-align: center;
  color: white;
}

#heroLogoFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.heroLogoLine.right {
  background-color: var(--white);
  /* background: linear-gradient(45deg, #ffffff 50%, #9f1f21 50%); */
    height: 3.5px;
  width: 38%;
  /* opacity: .8; */

}
.heroLogoLine.left {
    background-color: var(--white);
    /* opacity: .8; */
    /* background: linear-gradient(45deg, var(--blue) 50%, white 50%); */
    height: 3.5px;
  width: 38%;
}

#heroLogo {
  height: 13vw;
}

#heroText1 {
  line-height: 1.2;
  text-align: center;
  font-family: var(--fontMain);
  font-size: var(--fs-hero);
  margin-top: 10px;
  font-weight: 600;
}

#boldHeroText {
  background-color: transparent;
  color: white;
  font-weight: 600;
}

#heroBtnFlex {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

#heroBtn {
  font-size: var(--fs-small);
  font-weight: var(--btn-fontWeight);
  width: 180px;
  height: 45px;
  background-color: var(--blue);
  border: solid transparent 1px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--fontMain);
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 4px;
}
#heroBtn:hover {
 border: solid white 1px;

}

#heroBtn:hover #actNowArrow {
  margin-left: 18px;

}

#actNowArrow {
  width: 20px;
  margin-left: 15px;
  transition: all 0.2s ease-out;
}

#heroVideoContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  /* align-items: center; */
  /* justify-content: center; */
  /* object-fit: cover; */
}
#heroMedia {
  /* width: 80%; */
  padding-top: -100px;
  margin: auto;
}


/* SECTION 1 ======================================================= */
#section1 {
  position: relative;
  background-color: #ffffff;
  width: 100vw;
  padding-top: var(--large-spacing);
  padding-bottom: var(--large-spacing);
  /* height: 100vh; */
  scroll-margin: 50px;
  z-index:6;

}

#section1Flex {
  max-width: 1200px;
  margin: auto;
}

#section1Area {
  width: 95%;
}

#section1TopText {
  color: var(--gray);
  font-size: var(--fs-subtitle);
  font-family: var(--fontMain);
  font-weight: var(--subtitle-fontWeight);
  text-transform: uppercase;
}

#section1HeaderText {
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: var(--title-fontWeight);
  font-size: var(--fs-title);
  color: var(--blue);
  font-family: var(--fontMain);
  text-transform: capitalize;
  line-height: 1.2;
}

#section1SubText {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 1.8;
  font-size: var(--fs-ptag);
  font-family: "SourceSansPro";
  color: var(--blue);
}

#section1ImgFlex {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 48px;

}

#section1ImgArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 95%;
  /* position: absolute;
    left: 260px; */
}

.section1Highlight {
    /* box-shadow: -5px 5px 0px .5px var(--red); */
    /* border: var(--red) solid 5px; */
  overflow: hidden;
  height: 250px;
  width: 32%;
  border-radius: 0;
  object-fit: cover;
  cursor: pointer;
  background-size: cover;
  position: relative;
  transition: box-shadow .2s ease-in-out;
  border-radius: 5px;
}
.section1Highlight:hover {
    box-shadow: 0px 0px 0px 0px transparent;
}
.section1Highlight:nth-child(1) {

  background-image: url("https://www.roundrocktexas.gov/wp-content/uploads/2019/10/Downtown-Rain-Main-Street-Tower-Cloudy.jpg");
}

.section1Highlight:nth-child(2) {
  background-image: url("https://www.roundrocktexas.gov/wp-content/uploads/2021/06/06_21_AERIAL_TOWER_002.jpg");
}

.section1Highlight:nth-child(3) {
  background-image: url("https://goroundrock.com/wp-content/uploads/2020/08/IMG_676B0B92BE72-1-1024x768.jpg");
}

.highlightFade {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.514) 0%,
    #14171a00 60%,
    #14171ada 100%
  );
}

.highlightTitle {
  text-transform: capitalize;
  color: white;
  font-family: var(--fontMain);
  padding: 20px;
  font-size: var(--fs-subtitle);
}

.learnMoreHighlight {
  position: absolute;
  top: calc(100% - 45px);
  width: 100%;
  /* background-color: red; */
  height: 200px;
  transition: all 0.2s ease-out;
}

.highlightInfo {
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  font-family: "SourceSansPro";
  margin-top: 45px;
  font-size: var(--fs-ptag);
}

.learnMoreBtn {
  position: absolute;
  display: flex;
  align-items: center;
  right: 15px;
  bottom: 20px;
  color: rgba(255, 255, 255, 0.82);
  font-weight: 500;
  font-family: var(--fontMain);
  font-size: var(--fs-small);
  text-transform: capitalize;
}

.section1Highlight:hover .learnMoreBtn {
  color: white;
}

#learnMoreIcon {
  width: 35px;
  margin-left: 10px;
  transition: all 0.2s ease-out;
}

.highlightBlur {
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-out;
  position: relative;
}

#highlightOpen .highlightBlur {
  background-color: rgba(0, 0, 0, 0.6);
}

#highlightOpen .learnMoreHighlight {
  top: 20px;
}

#highlightOpen #learnMoreIcon {
  /* transform: rot(180deg); */
  rotate: 180deg;
}


#faqBox {
  box-shadow: rgba(0, 0, 0, 0.157) 0px 0.602187px 0.602187px -1.08333px,
    rgba(0, 0, 0, 0.145) 0px 2.28853px 2.28853px -2.16667px,
    rgba(0, 0, 0, 0.086) 0px 10px 10px -3.25px;
}
#scollSup{
  scroll-margin: 50px;
}
#scollForm{
  scroll-margin: 65px;
}
#scrollFAQ{
  scroll-margin: 50px;
}
@media only screen and (max-width: 825px) {
  #flagConatiner{
    display:none;
  }
  #heroVideoContainer {
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* background-position: -200px center; */
    /* background-image: url(https://testsiterd.kinsta.cloud/wp-content/uploads/2023/04/Background.png); */
  }
  #heroMedia {
    /* width: 100vw; */
    height: 600px;
    object-fit: fill;
    margin-right: -400px;
    margin-top: 5%;
  }
  #section1ImgFlex {
    /* margin-top: 5%; */
  }

  #section1ImgArea {
    display: block;
  }

  .section1Highlight {
    width: 100%;
    margin-bottom: 20px;
  }
  #heroLogo {
    height: 125px;
  }
#section1 {
  width: 90vw;
  margin: auto;
  padding-top: var(--medium-spacing);
  padding-bottom: var(--medium-spacing);
}
  #section1HeaderText {

  }

  #section1SubText {
    padding-top: 0;
    padding-bottom: 0;
  }
  #highlightOpen .highlightInfo {
    margin-top: 30px;
  }
  #section1ImgArea {
 width: 100%;

  }
  #heroSection{
    height: 600px;
  }
  .heroLogoLine.right {

    width: 35%;
  }
  .heroLogoLine.left {

    width: 35%;
  }
  #heroBtn {
    margin-top: 20px;
    width: 150px;
    height: 35px;
  }
  #heroText1 {
    margin: auto;
   margin-top: 20px;
   width: 88%;
  }
  #scollForm{
    scroll-margin: 90px;
  }
  #heroFlex{
    background-color: rgba(0, 0, 0, 0.41);
    padding-top: 35%;

  }
}



/* SECTION 2 ======================================================= */

