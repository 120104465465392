

/* footer section ======================================================================= */
footer{
  position: relative;
  font-family: var(--fontMain);
  max-width: 1200px;
  margin: auto;
  padding: var(--medium-spacing) ;
    /* background-color: rgb(235, 231, 231); */
    background-color: rgb(255, 255, 255);
}
footer a{
  color: var(--gray);
  text-decoration: none;
}
#copyWrite{
  padding-top: 25px;
  margin-bottom: -25px;
  text-align: center;
  width: 100%;
  color: rgba(0, 0, 0, 0.425);
  font-size: var(--fs-sx);
  z-index: 2;

}
#footer{
   border-bottom: rgba(0, 0, 0, 0.182) solid 2px;
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    z-index: 2;

}
.footerSections{
  flex: 1 32%;
}
/* Left side of footer */
#leftFooter{
  display: flex;
}
#footerLogo{
  width: 75px;
  height: 75px;
  padding-right: 20px;
  background-image: url("../../assets/logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  filter:invert(100);
}
#logoWords{
  padding-left: 20px;
  border-left: black solid 1px;
  height: fit-content;
  height: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#logoWords h5{
  font-size: var(--fs-small);
  text-transform: capitalize;
  font-weight: 400;
}
#logoWords p{
  text-transform: capitalize;
  font-weight: 300;
  font-size: var(--fs-sx);
}
/* mid side of footer */
#midFooter{
  color: rgba(0, 0, 0, 0.425);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--fs-small);
}
#midFooter>div{
  padding-bottom: 0px;
}
/* right side of footer */
#rightFooter{
  display: flex;
  align-items: center;
  justify-content: center;
}

#rightFooter > div{
  flex: 1 1 100%;
}
/* btn */
#contactBtn{
  display: flex;
  align-items: center;
  justify-content: end;
  color: white;

}
#contactBtn p{
  background-color: #001566;
  width: 175px;
    padding: 15px 8px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
}
#contactBtn p span{
  margin-left: 10px;
  /* border: white 2px solid; */
  border-radius: 100%;
  padding: 4.5px 5px;
}
/* Social icons */
#lab_social_icon_footer{
    display: flex;
    justify-content: end;
    /* padding-right: 20px; */
  }
#lab_social_icon_footer .social{
    width: 35px;
  }
  
  #lab_social_icon_footer a {
    color: #333;
  }
  
  #lab_social_icon_footer .social:hover {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
  }
  
  #lab_social_icon_footer .social {
    -webkit-transform: scale(0.8);
    /* Browser Variations: */
    
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
  }
  /*
      Multicoloured Hover Variations
  */
  
  #lab_social_icon_footer #social-fb:hover {
    fill: #ee6f00;
  }
  
  #lab_social_icon_footer #social-tw:hover {
    fill: #4099FF;
  }
  
  #lab_social_icon_footer #social-gp:hover {
    fill: #ed2106;
  }
  
  #lab_social_icon_footer #social-face:hover {
    fill: #12a8f3;
  }
  #lab_social_icon_footer #social-em:hover {
    fill: #122cf3;
  }



@media screen and (max-width: 768px) {
  footer{
  }
  #copyWrite{
    font: var(--fs-small);

  }
  #footer{
     border-bottom: rgba(0, 0, 0, 0.182) solid 2px;
      flex-wrap: wrap;
      flex-direction: column;

  }

  /* Left side of footer */
  #leftFooter{
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
  }
  #footerLogo{
    width: 50px;
    height: 50px;
    padding-right: 0;
  }
  #logoWords{
    padding-left: 15px;
    border-left: black solid 1px;
    height: fit-content;
  }
  #logoWords h5{
    font-size: 18px;
  }
  #logoWords p{
    font-size: 14px;
  }
  /* mid side of footer */
  #midFooter{
    text-align: center;
    padding-left: 0;
    margin-bottom: 20px;

  }
  #midFooter > div{
    margin-bottom:0px;

  }
  /* right side of footer */
  #rightFooter{
    justify-content: center;

  }
  
  #rightFooter > div{
    display: block;
    text-align: center;

  }
  /* btn */
  #contactBtn{
    justify-content: center;
    margin-bottom: 25px;
  }
  #contactBtn p{
    background-color: #001566;
    width: 175px;
      padding: 15px 8px;
      text-align: center;
      text-transform: uppercase;
      font-weight: 600;
  }
  #lab_social_icon_footer{
      padding-right: 0;
    }
  #lab_social_icon_footer .social{
      width: 30px;
    }

  
}