/* form section ======================================================================= */
#formSection {
    padding-top: var(--medium-spacing);
    padding-bottom: var(--medium-spacing);
    max-width: 1200px;
    margin: auto;
    font-family: var(--fontMain);

}
@media only screen and (max-width: 800px) {
    #formSection{
        padding: 0px;
        }
    
}
#formContainer {
    display: flex;
}
@media only screen and (max-width: 800px) {
    #formContainer{
        width:90vw;
       margin: auto;
    }
    
}
#formImgDiv{
    z-index: 3;
    width: 55%;
    background-image: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(https://testsiterd.kinsta.cloud/wp-content/uploads/2023/04/round-rock-image.jpg);
    /* background-position: 0 0, 50%; */
    background-repeat: repeat, repeat;
    background-size: auto, cover;
    position: relative;
}

@media only screen and (max-width: 800px) {
    #formImgDiv{
       display: none;
    }
    
}
#formContent{
    z-index: 4;
    width: 45%;
    min-height: 800px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
}
@media only screen and (max-width: 800px) {
    #formContent{
        width: calc(100% - 50px);
        display: block;

        }
    
}
#formWrap{
    max-width: 100%;
    background-color: #fff;
    position: relative;
    box-shadow: 0 0 50px rgba(0, 0, 0, .15);
    margin-left: -36px; 
    padding: 50px;
}
@media only screen and (max-width: 800px) {
    #formWrap{
        margin-left: 0px; 
        min-width: 100%;
        padding: 25px;
        border-radius: 8px;

    }
    
}
#formContent h3{
    text-align: center;
    font-size: var(--fs-title);
    color: var(--blue);
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: var(--fontMain);
    font-weight: var(--title-fontWeight);
    text-transform: capitalize;
}
#form{
    grid-column-gap: 10px;
    flex-flow: wrap;
    justify-content: space-between;
    margin-top: 40px;
    display: flex;
    font-weight: 400;
}
.textField {
    font-size: var(--fs-xs);
    height: 33px;
    margin-bottom: 10px;
    min-width: 43%;
    background-color: rgba(34, 54, 69, .05);
    border: 1px rgba(34, 54, 69, .05);
    padding: 15px 13px;
}
@media only screen and (max-width: 800px) {
    .textField{
        min-width: calc(100% - 26px);
        border-radius: 8px;

    }
    #formContent h3{
        text-align: center;
        font-size: 30px;
    }
    #textArea {
        border-radius: 8px;
    }
    #buttonForm{
        border-radius: 8px;
    }
    
}
#textArea {
    min-height: 130px;
    background-color: rgba(34, 54, 69, .05);
    border: 1px rgba(34, 54, 69, .05);
    padding: 16px 18px;
    width: 100%;
    font-size: var(--fs-xs);
    font-family: var(--fontMain);

}
#btnFormContainer{
    width: 100%;
}
#buttonForm {
    width: calc(100% - 80px);
    background-color: var(--blue);
    text-transform: uppercase;
    justify-content: center;
    margin: 40px 0;
    font-size: var(--fs-ptag);
    font-weight: var(--btn-fontWeight);
    line-height: 1.15;
    padding: 0px 40px;
    height: 60px;
    color: var(--white);
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

}
#formErrorMsg {
    color: red;
    margin-top: 10px;
}

.loader {
    margin-bottom: 0px;
    border: 3px solid var(--grayMain);
    border-top: 3px solid white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 0.4s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}