:root {
  --color-text: navy;
  --color-bg: papayawhip;
  --color-bg-accent: #ecdcc0;
  --size: clamp(10rem, 5rem + 10vmin, 50rem);
  --gap: calc(var(--size) / 10);
  --duration: 15s;
  --scroll-start: 5%;
  --scroll-end: calc(-95% - var(--gap));
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-text: papayawhip;
    --color-bg: navy;
    --color-bg-accent: #2626a0;
  }
}

#outerContainerSup {
  position: relative;
  padding-top: var(--medium-spacing);
  padding-bottom: var(--medium-spacing);
  display: grid;
  align-content: center;
  overflow: hidden;
  /* gap: var(--gap); */
  width: 100%;
  min-height: 40vh;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--color-text);
}

#outerContainerSup h1 {
  text-align: center;
  color: var(--gray);
  font-size: var(--fs-subtitle);
  font-weight: var(--subtitle-fontWeight);
  font-family: var(--fontMain);


}
@media only screen and (max-width: 800px) {
  #outerContainerSup {
    padding-top: 35px;
    padding-bottom: 35px;
 
  }
  #outerContainerSup h1 {
    padding-bottom: 20px;
  
  
  }
}
.marquee {
  display: flex;
  overflow: hidden;
  user-select: none;
  /* gap: var(--gap); */
  mask-image: linear-gradient(var(--mask-direction, to right),
      hsl(0 0% 0% / 0),
      hsl(0 0% 0% / 1) 15%,
      hsl(0 0% 0% / 1) 85%,
      hsl(0 0% 0% / 0));
}

.marquee__group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  animation: scroll-x var(--duration) linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .marquee__group {
    animation-play-state: paused;
  }
}

.marquee--vertical {
  --mask-direction: to bottom;
}

.marquee--vertical,
.marquee--vertical .marquee__group {
  flex-direction: column;
}

.marquee--vertical .marquee__group {
  animation-name: scroll-y;
}

.marquee--reverse .marquee__group {
  animation-direction: reverse;
  animation-delay: -3s;
}

@keyframes scroll-x {
  0% {
    transform: translateX(var(--scroll-start));
  }
  100% {
    transform: translateX(var(--scroll-end));
  }
}

@keyframes scroll-y {
  from {
    transform: translateY(var(--scroll-start));
  }
  to {
    transform: translateY(var(--scroll-end));
  }
}

/* Element styles */

.marquee img {
  /* filter: grayscale(100); */
  /* opacity: .8; */
  display: grid;
  place-items: center;
  width: var(--size);
  fill: var(--color-text);
  /* background: var(--color-bg-accent);  */
  /* aspect-ratio: 16/16; */
  /* padding: calc(var(--size) / 10); */
  border-radius: 0.5rem;
}
.whiteLogo{
  filter: invert(100%);
}
.marquee--vertical svg {
  aspect-ratio: 1;
  width: calc(var(--size) / 1.5);
  height: calc(var(--size) / 1.5);
  /* padding: calc(var(--size) / 6); */
}

/* #smoke{
  width: 15vw;
} */

/* Parent wrapper */
.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  margin: auto;
  width: 95vw;
}

.wrapper--vertical {
  flex-direction: row;
  height: 100vh;
}

@keyframes fade {
  to {
    opacity: 0;
    visibility: hidden;
  }
}